function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export var userSlice = createSlice({
  name: "user",
  initialState: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
    userid: null,
    firsname: '',
    lastname: '',
    jobprofile: '',
    mobile: '',
    employed: '',
    avatar: '',
    email: '',
    status: '',
    jobnanme: ''
  }, "email", ""), "isUserFetching", false), "isLoginSuccess", false), "isLoginError", false), "errorMessage", ""),
  reducers: {
    // Reducer comes here
    clearState: function clearState(state) {
      state.isLoginError = false;
      state.isLoginSuccess = false;
      state.isUserFetching = false;
      return state;
    }
  },
  extraReducers: {
    // Extra reducer comes here
    /*[loginUser.fulfilled]: (state, { payload }) => {
      state.email = payload.email;
      state.firstname = payload.firstname;
      state.lastname = payload.lastname;
      state.mobile = payload.mobile;
      state.jobnanme = payload.jobnanme;
      state.jobprofile= payload.jobprofile;
      state.employed = payload.employed;
      state.status = payload.status;
      state.avatar = payload.avatar;  
      state.userid = payload.id;
       state.isUserFetching = false;
      state.isLoginSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      console.log('payload', payload);
      state.isUserFetching = false;
      state.isLoginError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isUserFetching = true;
    },*/
  }
});
export var userSelector = function userSelector(state) {
  return state.user;
};